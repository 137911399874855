import { ITheme } from "../interfaces/ITheme";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ISubTheme } from "../interfaces/ISubTheme";

@Injectable()
export class Config {
  public apiUrl = "/api";
  public employeePerkSpecialtyID = 38;
  public officeSuppliesSpecialtyID = 40;
  public pharmacySpecialtyID = 44;
  public orthopedicSpecialtyID = 39;
  public orthopedicNonSurgerySpecialtyID = 216;
  public defaultThemeID = 1;
  public ampThemeID = 2;
  public capThemeID = 5;
  public mgmaThemeID = 4;
  public mrnThemeID = 7;
  public tenetThemeID = 8;
  public aoaThemeID = 9;
  public commThemeID = 11;
  public plusThemeID = 12;
  public aadThemeID = 16;
  public ampSpecialtyID = 45;
  public plasticSurgerySpecialtyID = 45;
  public defaultTheme: ITheme = <ITheme>{};
  public distributors_question_id = 5;
  public distributors_id_to_send_email = [25, 26, 27, 28];
  public questionIdContactSomebodyElse = 9;
  public subQuestionContactSomebodyElse = 211;
  public subQuestionOptionUseSpect = 249;
  public subQuestionOptionUseStaples = 227;
  public subQuestionOptionUseFedex = 232;
  public subQuestionOptionUseEnterprise = 234;
  public subQuestionOptionUseATT = 229;
  public subQuestionSpectDistr = 83;
  public questionIdMedicalDistributor = 10;
  public questionIdOtherDistributors = 68;
  public questionIdMedicalDistributorName = 23;
  public classOfTradeSurgeryCenter = 27;
  public classOfTradeAcute = 28;
  public questionIdProductCategory = 11;
  public questionIdSpecialty = 21;
  public questionIdOtherPrimaryDist = 70;
  public questionIdSecDistrRep = 90;
  public questionIdCot = 22;
  public questionIdRepA = 23;
  public questionIdRepB = 71;
  public staplesVendorID = 4;
  public fedexVendorID = 5;
  public attVendorID = 1;
  public enterpriseVendorID = 6;
  public fileTypeUtilityBill = 4;
  public fileTypeScreenshot = 10;
  public cotIDClinic = 5;
  public themesAreLoaded = false;
  public themesMap: any = {
    1: {
      id: 1,
      main_logo_url: "//ascadvantage.s3.amazonaws.com/img/AT_logo_stacked_tag_white_rev21.06.png",
      phone: "877.659.2159",
      footer_logo_url: "",
      welcome_message: "Welcome to Purchase Clinic.",
      client_manager_id: 0,
      contact_email: "",
      facebook_url: "https://www.facebook.com/pages/Purchase-Clinic/745938055463808",
      linkedin_url: "https://www.linkedin.com/company/purchase-clinic",
      header_logo_link: "https://purchaseclinic.com",
      window_title: "Purchase Clinic",
      faq_url: "",
      signup_url: "https://savings.purchaseclinic.com/signup",
      analytics_js_id: "vTCdDP3gAhYS0UNoorEErs3o8CxxjTXI",
      olark_id: "5695-386-10-5381",
      name: "Purchase Clinic",
      signup_target_id: 0,
      email_from_name: "Purchase Clinic",
      email_from_address: "support@purchaseclinic.com",
      login_heading: "",
      landing_domain: "savings.purchaseclinic.com",
      welcome_subject_for_facility: "",
      specs_widget_caption: "",
      login_heading_img_url: "",
      specs_widget_img_url: "",
      task_from_user_id: 20524,
      tos: null,
      slug: "pc",
    },
    2: {
      id: 2,
      main_logo_url: "//ampurchasing.s3.amazonaws.com/img/amp-logo-white.png",
      phone: "855.267.8700",
      footer_logo_url: "",
      welcome_message: "Welcome to Access Medical Purchasing.",
      client_manager_id: 0,
      contact_email: "amp@plasticsurgery.org",
      facebook_url: "https://www.facebook.com/accessmedicalpurchasing",
      linkedin_url:
        "https://www.linkedin.com/company/10176273?trk=tyah&trkInfo=clickedVertical%3Acompany%2CclickedEntityId%3A10176273%2Cidx%3A2-1-2%2CtarId%3A1454431670507%2Ctas%3Aaccess%20medical%20purchasing",
      header_logo_link: "https://accessmedicalpurchasing.com",
      window_title: "Access Medical Purchasing",
      faq_url: "https://accessmedicalpurchasing.com/faq",
      signup_url: "https://accessmedicalpurchasing.com/enroll",
      analytics_js_id: "tVwQa67JzkQdCeXhjxd9iuZVNPkYqj7r",
      olark_id: "1955-492-10-6536",
      name: "AMP",
      signup_target_id: 1,
      email_from_name: "Access Medical Purchasing",
      email_from_address: "amp@plasticsurgery.org",
      login_heading: "",
      landing_domain: "savings.ampurchasing.com",
      welcome_subject_for_facility: "",
      specs_widget_caption: "",
      login_heading_img_url: "",
      specs_widget_img_url: "",
      task_from_user_id: 20142,
      tos: "AMP utilizes Expansion, LLC and Purchase Clinic technology to help administer Access Medical Purchasing, a member benefit provided through the American Society of Plastic Surgeons.",
      slug: "amp",
    },
    3: {
      id: 3,
      main_logo_url: "//purchaseclinic.s3.amazonaws.com/logo/pc-logo-white-50.png",
      phone: "",
      footer_logo_url: "",
      welcome_message: "",
      client_manager_id: 0,
      contact_email: "",
      facebook_url: "",
      linkedin_url: "",
      header_logo_link: "",
      window_title: "Purchase Clinic",
      faq_url: "",
      signup_url: "",
      analytics_js_id: "vTCdDP3gAhYS0UNoorEErs3o8CxxjTXI",
      olark_id: "5695-386-10-5381",
      name: "Purchase Clinic",
      signup_target_id: 0,
      email_from_name: "Purchase Clinic",
      email_from_address: "support@purchaseclinic.com",
      login_heading: "",
      landing_domain: "savings.purchaseclinic.com",
      welcome_subject_for_facility: "",
      specs_widget_caption: "",
      login_heading_img_url: "",
      specs_widget_img_url: "",
      task_from_user_id: 0,
      tos: null,
      slug: "pc3",
    },
    4: {
      id: 4,
      main_logo_url: "//mgmabestpricefiles.s3.us-east-1.amazonaws.com/mgma-logo-black.png",
      phone: "844.234.6462 ",
      footer_logo_url: "",
      welcome_message: "MGMA + Purchase Clinic",
      client_manager_id: 0,
      contact_email: "support@mgmabestprice.org",
      facebook_url: "",
      linkedin_url: "",
      header_logo_link: "https://mgmabestprice.org",
      window_title: "MGMA BestPrice",
      faq_url: "https://mgmabestprice.org/faq/",
      signup_url: "https://mgmabestprice.org/",
      analytics_js_id: "Mm68n94Tmc9NbpFGZCszmEDQB0mI71Pn",
      olark_id: "5695-386-10-5381",
      name: "MGMA BestPrice",
      signup_target_id: 0,
      email_from_name: "MGMA BestPrice",
      email_from_address: "support@mgmabestprice.org",
      login_heading: "",
      landing_domain: "savings.mgmabestprice.org",
      welcome_subject_for_facility: "",
      specs_widget_caption: "",
      login_heading_img_url: "",
      specs_widget_img_url: "",
      task_from_user_id: 41960,
      tos: "MGMA BestPrice is provided as a free member benefit through membership in the MGMA. MGMA BestPrice accesses committed contract pricing through the HealthTrust (HPG), AdvantageTrust contract portfolio. In addition, MGMA BestPrice leverages Expansion, LLC and Purchase Clinic technology in the administration of the program.",
      slug: "mgma",
    },
    5: {
      id: 5,
      main_logo_url: "//cap-purchasing-alliance.s3.amazonaws.com/cap-logo.png",
      phone: "855.907.9227",
      footer_logo_url: "",
      welcome_message: "Welcome to CAP Purchasing Alliance.",
      client_manager_id: 0,
      contact_email: "support@cappurchasingalliance.com",
      facebook_url: "",
      linkedin_url: "",
      header_logo_link: "https://cappurchasingalliance.com",
      window_title: "CAP Purchasing Alliance",
      faq_url: "https://cappurchasingalliance.com/faq/",
      signup_url: "https://savings.cappurchasingalliance.com/signup",
      analytics_js_id: "Lip1GcVnqRsaAVsKjW4LWS6NDuGr5QGk",
      olark_id: "5695-386-10-5381",
      name: "CAP",
      signup_target_id: 2,
      email_from_name: "CAP Purchasing Alliance",
      email_from_address: "support@cappurchasingalliance.com",
      login_heading: "CAP Purchasing Alliance",
      landing_domain: "savings.cappurchasingalliance.com",
      welcome_subject_for_facility: "CAP Purchasing Alliance Login for",
      specs_widget_caption: "CAP Purchasing Alliance",
      login_heading_img_url: "//cap-purchasing-alliance.s3.amazonaws.com/cap_heading_black.svg",
      specs_widget_img_url: "//cap-purchasing-alliance.s3.amazonaws.com/cap_heading_black.svg",
      task_from_user_id: 41962,
      tos: "CAP Purchasing Alliance (CPA) is provided as a free member benefit through membership in the Cooperative of American Physicians. CPA accesses committed contract pricing through the HealthTrust (HPG), AdvantageTrust contract portfolio. In addition, CPA leverages Expansion, LLC and Purchase Clinic technology in the administration of the program.",
      slug: "cap",
    },
    6: {
      id: 6,
      main_logo_url: "//ascadvantage.s3.amazonaws.com/img/AT_logo_stacked_tag_white_rev21.06.png",
      phone: "",
      footer_logo_url: "",
      welcome_message: "Welcome to Purchase Clinic.",
      client_manager_id: 0,
      contact_email: "",
      facebook_url: "",
      linkedin_url: "",
      header_logo_link: "",
      window_title: "",
      faq_url: "",
      signup_url: "https://savings.purchaseclinic.com/signup",
      analytics_js_id: "vTCdDP3gAhYS0UNoorEErs3o8CxxjTXI",
      olark_id: "5695-386-10-5381",
      name: "Purchase Clinic",
      signup_target_id: 0,
      email_from_name: "Purchase Clinic",
      email_from_address: "support@purchaseclinic.com",
      login_heading: "",
      landing_domain: "savings.purchaseclinic.com",
      welcome_subject_for_facility: "",
      specs_widget_caption: "",
      login_heading_img_url: "",
      specs_widget_img_url: "",
      task_from_user_id: 0,
      tos: null,
      slug: "pc6",
    },
    7: {
      id: 7,
      main_logo_url: "//ascadvantage.s3.amazonaws.com/img/AT_logo_stacked_tag_white_rev21.06.png",
      phone: "",
      footer_logo_url: "",
      welcome_message: "Welcome to MedResults Network Med/Surg Supply Buying Program",
      client_manager_id: 0,
      contact_email: "",
      facebook_url: "https://www.facebook.com/pages/Purchase-Clinic/745938055463808",
      linkedin_url: "https://www.linkedin.com/company/purchase-clinic",
      header_logo_link: "",
      window_title: "MedResults Network Med/Surg Supply Buying Program",
      faq_url: "",
      signup_url: "https://savings.purchaseclinic.com/signup",
      analytics_js_id: "vTCdDP3gAhYS0UNoorEErs3o8CxxjTXI",
      olark_id: "5695-386-10-5381",
      name: "MedResults Network Med/Surg Supply Buying Program",
      signup_target_id: 0,
      email_from_name: "MedResults Network Med/Surg Supply Buying Program",
      email_from_address: "medresults@purchaseclinic.com",
      login_heading: "",
      landing_domain: "savings.purchaseclinic.com",
      welcome_subject_for_facility: "",
      specs_widget_caption: "",
      login_heading_img_url: "",
      specs_widget_img_url: "",
      task_from_user_id: 20524,
      tos: "MedResults Network utilizes the AdvantageTrust portfolio, Expansion, LLC staff and Purchase Clinic technology to help administer the MedResults Network Buying Group, a member benefit provided through the MedResults Network.",
      slug: "medresults",
    },
    8: {
      id: 8,
      main_logo_url:
        "//tenet-aco.s3-us-west-2.amazonaws.com/email/tenet-group-purchasing-program.png",
      phone: "855.836.3838",
      footer_logo_url: "",
      welcome_message: "",
      client_manager_id: 0,
      contact_email: "acosupport@tenetgpo.com",
      facebook_url: "",
      linkedin_url: "",
      header_logo_link: "https://savings.tenetgpo.com",
      window_title: "Tenet’s group purchasing program",
      faq_url: "",
      signup_url: "https://savings.tenetgpo.com/signup",
      analytics_js_id: "",
      olark_id: "5695-386-10-5381",
      name: "Tenet’s group purchasing program",
      signup_target_id: 0,
      email_from_name: "Tenet’s group purchasing program",
      email_from_address: "acosupport@tenetgpo.com",
      login_heading: "Tenet's group purchasing program",
      landing_domain: "savings.tenetgpo.com",
      welcome_subject_for_facility: "Your credentials for Tenet's group purchasing program -- for ",
      specs_widget_caption: "",
      login_heading_img_url: "",
      specs_widget_img_url: "",
      task_from_user_id: 53994,
      tos: null,
      slug: "tenet",
    },
    9: {
      id: 9,
      main_logo_url: "//aoapurchase.s3.amazonaws.com/images/aoa-purchase-power-white-wide.png",
      phone: "844.802.9262",
      footer_logo_url: "",
      welcome_message: "Welcome to AOA Purchase Power",
      client_manager_id: 0,
      contact_email: "aoasupport@aoapurchasepower.com",
      facebook_url: "",
      linkedin_url: "",
      header_logo_link: "https://aoapurchasepower.com",
      window_title: "AOA Purchase Power",
      faq_url: "",
      signup_url: "http://aoapurchasepower.com",
      analytics_js_id: "",
      olark_id: "5695-386-10-5381",
      name: "AOA Purchase Power",
      signup_target_id: 0,
      email_from_name: "AOA Purchase Power",
      email_from_address: "aoasupport@aoapurchasepower.com",
      login_heading: "AOA Purchase Power",
      landing_domain: "savings.aoapurchasepower.com",
      welcome_subject_for_facility: "",
      specs_widget_caption: "",
      login_heading_img_url: "",
      specs_widget_img_url: "",
      task_from_user_id: 55823,
      tos: "AOA Purchase Power is provided as a free member benefit through membership in the AOA. AOA Purchase Power accesses committed contract pricing through the HealthTrust (HPG), AdvantageTrust contract portfolio. In addition, AOA Purchase Power leverages Expansion, LLC and Purchase Clinic technology in the administration of the program.",
      slug: "aoa",
    },
    10: {
      id: 10,
      main_logo_url: "//ascadvantage.s3.amazonaws.com/img/AT_logo_stacked_tag_white_rev21.06.png",
      phone: "",
      footer_logo_url: "",
      welcome_message: "Welcome to Purchase Clinic.",
      client_manager_id: 0,
      contact_email: "",
      facebook_url: "https://www.facebook.com/pages/Purchase-Clinic/745938055463808",
      linkedin_url: "https://www.linkedin.com/company/purchase-clinic",
      header_logo_link: "",
      window_title: "Purchase Clinic",
      faq_url: "",
      signup_url: "https://savings.purchaseclinic.com/signup",
      analytics_js_id: "vTCdDP3gAhYS0UNoorEErs3o8CxxjTXI",
      olark_id: "5695-386-10-5381",
      name: "Purchase Clinic",
      signup_target_id: 0,
      email_from_name: "Purchase Clinic",
      email_from_address: "support@purchaseclinic.com",
      login_heading: "",
      landing_domain: "savings.purchaseclinic.com",
      welcome_subject_for_facility: "",
      specs_widget_caption: "",
      login_heading_img_url: "",
      specs_widget_img_url: "",
      task_from_user_id: 20524,
      tos: null,
      slug: "pc10",
    },
    11: {
      id: 11,
      main_logo_url: "//s3-us-west-2.amazonaws.com/communitypurchasing/cpp-white-logo.png",
      phone: "866.436.1277",
      footer_logo_url: "",
      welcome_message: "Welcome to Community Purchasing Program",
      client_manager_id: 0,
      contact_email: "support@communitypurchasingprogram.com",
      facebook_url: "",
      linkedin_url: "",
      header_logo_link: "https://communitypurchasingprogram.com",
      window_title: "Community Purchasing Program",
      faq_url: "",
      signup_url: "https://communitypurchasingprogram.com",
      analytics_js_id: "vTCdDP3gAhYS0UNoorEErs3o8CxxjTXI",
      olark_id: "5695-386-10-5381",
      name: "Community Purchasing Program",
      signup_target_id: 0,
      email_from_name: "Community Purchasing Program",
      email_from_address: "support@communitypurchasingprogram.com",
      login_heading: "Community Purchasing Program",
      landing_domain: "savings.communitypurchasingprogram.com",
      welcome_subject_for_facility: "",
      specs_widget_caption: "",
      login_heading_img_url: "",
      specs_widget_img_url: "",
      task_from_user_id: 94200,
      tos: "Community Purchasing Program is provided as a free member benefit. Community Purchasing Program accesses committed contract pricing through the HealthTrust (HPG), AdvantageTrust contract portfolio. In addition, Community Purchasing Program leverages Expansion, LLC and Purchase Clinic technology in the administration of the program.",
      slug: "comm",
    },
    12: {
      id: 12,
      main_logo_url: "//purchasingplus.s3.amazonaws.com/service-line.png",
      phone: "877.659.2159",
      footer_logo_url: "",
      welcome_message: "Welcome to Purchasing Plus",
      client_manager_id: 0,
      contact_email: "purchasingplus@purchaseclinic.com",
      facebook_url: "",
      linkedin_url: "",
      header_logo_link: "",
      window_title: "Purchasing Plus",
      faq_url: "",
      signup_url: "https://purchasing.plus/",
      analytics_js_id: "vTCdDP3gAhYS0UNoorEErs3o8CxxjTXI",
      olark_id: "5695-386-10-5381",
      name: "Purchase Clinic (Purchasing Plus)",
      signup_target_id: 0,
      email_from_name: "Purchasing Plus",
      email_from_address: "purchasingplus@purchaseclinic.com",
      login_heading: "",
      landing_domain: "savings.purchasing.plus",
      welcome_subject_for_facility: "Your Purchasing Plus Program credentials",
      specs_widget_caption: "",
      login_heading_img_url: "",
      specs_widget_img_url: "",
      task_from_user_id: 0,
      tos: "Purchasing Plus is provided as a free member benefit. Purchasing Plus accesses committed contract pricing through the HealthTrust (HPG), AdvantageTrust contract portfolio. In addition, Purchasing Plus leverages Expansion, LLC and Purchase Clinic technology in the administration of the program.",
      slug: "plus",
    },
    16: {
      id: 16,
      main_logo_url: "//aadmbp.s3.amazonaws.com/aad-member-buying-program.jpg",
      phone: "855.962.3376",
      footer_logo_url: "",
      welcome_message: "Welcome to AAD Member Buying Program",
      client_manager_id: 0,
      contact_email: "support@aadmemberbuyingprogram.com",
      facebook_url: "",
      linkedin_url: "",
      header_logo_link: "https://aadmemberbuyingprogram.com",
      window_title: "AAD Member Buying Program",
      faq_url: "",
      signup_url: "https://aadmemberbuyingprogram.com",
      analytics_js_id: "vTCdDP3gAhYS0UNoorEErs3o8CxxjTXI",
      olark_id: "5695-386-10-5381",
      name: "AAD Member Buyng Program",
      signup_target_id: 0,
      email_from_name: "AAD Member Buying Program",
      email_from_address: "support@aadmemberbuyingprogram.com",
      login_heading: "AAD Member Buying Program",
      landing_domain: "savings.aadmemberbuyingprogram.com",
      welcome_subject_for_facility: "",
      specs_widget_caption: "",
      login_heading_img_url: "",
      specs_widget_img_url: "",
      task_from_user_id: 0,
      tos: "AAD Member Buying Program is provided as a free member benefit through membership in the AAD. AAD Member Buying Program accesses committed contract pricing through the HealthTrust (HPG), AdvantageTrust contract portfolio. In addition, AAD Member Buying Program leverages Expansion, LLC and Purchase Clinic technology in the administration of the program.",
      slug: "aadmbp",
    },
  };
  public subThemesList: ISubTheme[] = [
    {
      id: 1,
      message: "MedResults Network Med/Surg Supply Buying Program",
      tos: "MedResults Network utilizes the AdvantageTrust portfolio, Expansion, LLC staff and Purchase Clinic technology to help administer the MedResults Network Buying Group, a member benefit provided through the MedResults Network.",
      logo_url: "https://medresults.s3.amazonaws.com/mrn-logo-white.png",
      label: "mrn",
      name: "MRN",
      parent_theme_id: 7,
    },
    {
      id: 33,
      message: "Welcome to Tenet’s group purchasing program",
      tos: "Tenet’s group purchasing program is provided as a free member benefit through membership in Advantage Health Network. Tenet’s group purchasing program accesses committed contract pricing through the HealthTrust (HPG), AdvantageTrust contract portfolio. In addition, Tenet’s group purchasing program leverages Expansion, LLC and Purchase Clinic technology in the administration of the program.",
      logo_url: "https://tenet-aco.s3-us-west-2.amazonaws.com/img/ahn.png",
      label: "ahn",
      name: "Advantage Health Network",
      parent_theme_id: 8,
    },
    {
      id: 34,
      message: "Welcome to Tenet’s group purchasing program",
      tos: "Tenet’s group purchasing program is provided as a free member benefit through membership in Baptist Integrated Physician Partners. Tenet’s group purchasing program accesses committed contract pricing through the HealthTrust (HPG), AdvantageTrust contract portfolio. In addition, Tenet’s group purchasing program leverages Expansion, LLC and Purchase Clinic technology in the administration of the program.",
      logo_url: "https://tenet-aco.s3-us-west-2.amazonaws.com/img/bipp.png",
      label: "bac",
      name: "Baptist Integrated Physician Partners",
      parent_theme_id: 8,
    },
    {
      id: 35,
      message: "Welcome to Tenet’s group purchasing program",
      tos: "Tenet’s group purchasing program is provided as a free member benefit through membership in Brookwood Baptist Health Physician Alliance. Tenet’s group purchasing program accesses committed contract pricing through the HealthTrust (HPG), AdvantageTrust contract portfolio. In addition, Tenet’s group purchasing program leverages Expansion, LLC and Purchase Clinic technology in the administration of the program.",
      logo_url: "https://tenet-aco.s3-us-west-2.amazonaws.com/img/bbh.png",
      label: "bbpa",
      name: "Brookwood Baptist Health Physician Alliance",
      parent_theme_id: 8,
    },
    {
      id: 36,
      message: "Welcome to Tenet’s group purchasing program",
      tos: "Tenet’s group purchasing program is provided as a free member benefit through membership in Children's Hospital of Michigan Premier Network. Tenet’s group purchasing program accesses committed contract pricing through the HealthTrust (HPG), AdvantageTrust contract portfolio. In addition, Tenet’s group purchasing program leverages Expansion, LLC and Purchase Clinic technology in the administration of the program.",
      logo_url: "https://tenet-aco.s3-us-west-2.amazonaws.com/img/chmpn.png",
      label: "chmpn",
      name: "Children's Hospital of Michigan Premier Network",
      parent_theme_id: 8,
    },
    {
      id: 37,
      message: "Welcome to Tenet’s group purchasing program",
      tos: "Tenet’s group purchasing program is provided as a free member benefit through membership in DMC Premier Network. Tenet’s group purchasing program accesses committed contract pricing through the HealthTrust (HPG), AdvantageTrust contract portfolio. In addition, Tenet’s group purchasing program leverages Expansion, LLC and Purchase Clinic technology in the administration of the program.",
      logo_url: "https://tenet-aco.s3-us-west-2.amazonaws.com/img/dmc.png",
      label: "dmcpn",
      name: "DMC Premier Network",
      parent_theme_id: 8,
    },
    {
      id: 38,
      message: "Welcome to Tenet’s group purchasing program",
      tos: "Tenet’s group purchasing program is provided as a free member benefit through membership in The Hospitals of Providence Physician Performance Network. Tenet’s group purchasing program accesses committed contract pricing through the HealthTrust (HPG), AdvantageTrust contract portfolio. In addition, Tenet’s group purchasing program leverages Expansion, LLC and Purchase Clinic technology in the administration of the program.",
      logo_url: "https://tenet-aco.s3-us-west-2.amazonaws.com/img/hpppn.png",
      label: "hpppn",
      name: "The Hospitals of Providence Physician Performance Network",
      parent_theme_id: 8,
    },
    {
      id: 39,
      message: "Welcome to Tenet’s group purchasing program",
      tos: "Tenet’s group purchasing program is provided as a free member benefit through membership in Michigan Pioneer ACO. Tenet’s group purchasing program accesses committed contract pricing through the HealthTrust (HPG), AdvantageTrust contract portfolio. In addition, Tenet’s group purchasing program leverages Expansion, LLC and Purchase Clinic technology in the administration of the program.",
      logo_url: "https://tenet-aco.s3-us-west-2.amazonaws.com/img/mipaco.png",
      label: "mipaco",
      name: "Michigan Pioneer ACO",
      parent_theme_id: 8,
    },
    {
      id: 40,
      message: "Welcome to Tenet’s group purchasing program",
      tos: "Tenet’s group purchasing program is provided as a free member benefit through membership in Total Accountable Care Organization. Tenet’s group purchasing program accesses committed contract pricing through the HealthTrust (HPG), AdvantageTrust contract portfolio. In addition, Tenet’s group purchasing program leverages Expansion, LLC and Purchase Clinic technology in the administration of the program.",
      logo_url: "https://tenet-aco.s3-us-west-2.amazonaws.com/img/n-a.png",
      label: "necollab",
      name: "Total Accountable Care Organization",
      parent_theme_id: 8,
    },
    {
      id: 41,
      message: "Welcome to Tenet’s group purchasing program",
      tos: "Tenet’s group purchasing program is provided as a free member benefit through membership in Physician Performance Network Tucson. Tenet’s group purchasing program accesses committed contract pricing through the HealthTrust (HPG), AdvantageTrust contract portfolio. In addition, Tenet’s group purchasing program leverages Expansion, LLC and Purchase Clinic technology in the administration of the program.",
      logo_url: "https://tenet-aco.s3-us-west-2.amazonaws.com/img/ppn-tuscon.png",
      label: "ppnt",
      name: "Physician Performance Network Tucson",
      parent_theme_id: 8,
    },
    {
      id: 42,
      message: "Welcome to Tenet’s group purchasing program",
      tos: "Tenet’s group purchasing program is provided as a free member benefit through membership in Baptist Integrated Physician Partners. Tenet’s group purchasing program accesses committed contract pricing through the HealthTrust (HPG), AdvantageTrust contract portfolio. In addition, Tenet’s group purchasing program leverages Expansion, LLC and Purchase Clinic technology in the administration of the program.",
      logo_url: "https://tenet-aco.s3-us-west-2.amazonaws.com/img/bipp.png",
      label: "stcc",
      name: "Baptist Integrated Physician Partners",
      parent_theme_id: 8,
    },
    {
      id: 43,
      message: "Welcome to Tenet’s group purchasing program",
      tos: "Tenet’s group purchasing program is provided as a free member benefit through membership in Physician Performance Network Valley Baptist. Tenet’s group purchasing program accesses committed contract pricing through the HealthTrust (HPG), AdvantageTrust contract portfolio. In addition, Tenet’s group purchasing program leverages Expansion, LLC and Purchase Clinic technology in the administration of the program.",
      logo_url: "https://tenet-aco.s3-us-west-2.amazonaws.com/img/ppn-vb.png",
      label: "vbppn",
      name: "Physician Performance Network Valley Baptist",
      parent_theme_id: 8,
    },
    {
      id: 44,
      message: "Welcome to Tenet’s group purchasing program",
      tos: "Tenet’s group purchasing program is provided as a free member benefit through membership in Chicago Health Systems. Tenet’s group purchasing program accesses committed contract pricing through the HealthTrust (HPG), AdvantageTrust contract portfolio. In addition, Tenet’s group purchasing program leverages Expansion, LLC and Purchase Clinic technology in the administration of the program.",
      logo_url: "https://tenet-aco.s3-us-west-2.amazonaws.com/img/chs.png",
      label: "chicago",
      name: "Chicago Health Systems",
      parent_theme_id: 8,
    },
    {
      id: 45,
      message: "Welcome to Tenet’s group purchasing program",
      tos: "Tenet’s group purchasing program is provided as a free member benefit through membership in Premier. Tenet’s group purchasing program accesses committed contract pricing through the HealthTrust (HPG), AdvantageTrust contract portfolio. In addition, Tenet’s group purchasing program leverages Expansion, LLC and Purchase Clinic technology in the administration of the program.",
      logo_url: "https://tenet-aco.s3-us-west-2.amazonaws.com/img/premier.png",
      label: "premieraco",
      name: "Premier ACO",
      parent_theme_id: 8,
    },
    {
      id: 46,
      message: "Welcome to Tenet’s group purchasing program",
      tos: "Tenet’s group purchasing program is provided as a free member benefit through membership in St. Louis IPA. Tenet’s group purchasing program accesses committed contract pricing through the HealthTrust (HPG), AdvantageTrust contract portfolio. In addition, Tenet’s group purchasing program leverages Expansion, LLC and Purchase Clinic technology in the administration of the program.",
      logo_url: "https://tenet-aco.s3-us-west-2.amazonaws.com/img/slpa.png",
      label: "stl",
      name: "St. Louis Physician Alliance",
      parent_theme_id: 8,
    },
    {
      id: 47,
      message: "Welcome to Tenet’s group purchasing program",
      tos: "Tenet’s group purchasing program is provided as a free member benefit through membership in Tenet ACO. Tenet’s group purchasing program accesses committed contract pricing through the HealthTrust (HPG), AdvantageTrust contract portfolio. In addition, Tenet’s group purchasing program leverages Expansion, LLC and Purchase Clinic technology in the administration of the program.",
      logo_url: "https://tenet-aco.s3-us-west-2.amazonaws.com/img/n-a-100.png",
      label: "tenet",
      name: "Tenet's group purchasing program",
      parent_theme_id: 8,
    },
    {
      id: 48,
      message: null,
      tos: "Robert Wood Johnson Integrated Health IPA purchasing program is provided as a free member benefit through membership in Advantage Health Network. Robert Wood Johnson Integrated Health IPA purchasing program accesses committed contract pricing through the HealthTrust (HPG), AdvantageTrust contract portfolio. In addition, Robert Wood Johnson Integrated Health IPA purchasing program leverages Expansion, LLC and Purchase Clinic technology in the administration of the program.",
      logo_url: null,
      label: "rwjih",
      name: "RWJIH",
      parent_theme_id: 8,
    },
    {
      id: 49,
      message: null,
      tos: null,
      logo_url: null,
      label: "mrn_new_new",
      name: null,
      parent_theme_id: 7,
    },
    {
      id: 50,
      message: "ASC Owners Purchasing Program",
      tos: "",
      logo_url: "http://purchaseclinic.com/ASCOwners/christus-pasc.jpg",
      label: "ASCOwners",
      name: "Santa Rosa Physician Ambulatory Surgery Centers",
      parent_theme_id: 10,
    },
    {
      id: 51,
      message: "",
      tos: "",
      logo_url: "http://purchaseclinic.com/imperial/imperial-logo.jpg",
      label: "Imperial",
      name: "Christus Santa Rosa Physicians Ambulatory Surgery Centers",
      parent_theme_id: 10,
    },
  ];

  constructor(private http: HttpClient) {
    if (
      location.host === "localhost:4200" ||
      location.host.indexOf("localhost:49") > -1 ||
      location.host === "unclevader.ddns.net:8080"
    ) {
      this.apiUrl = "http://dev-rest.purchase.guru";
    }
    // if (location.host === 'localhost:4200') {
    //   this.apiUrl = 'http://localhost:3000';
    // }
    this.setDefaultTheme();
    this.getActualThemes();
    try {
      window["pc2"] = {
        app_version: 86,
        api_version_required: "39.35.1",
        config: this,
      };
    } catch (e) {}
  }

  setDefaultTheme() {
    this.defaultTheme = this.themesMap[this.getThemeIDForHost()];
  }

  getThemeIDForHost() {
    let domain;
    try {
      let host = location.host;
      let names = host.split(".");

      if (names.length > 1) {
        domain = names[names.length - 2];
      } else {
        domain = names[0];
      }
    } catch (e) {
      return this.defaultThemeID;
    }
    switch (domain) {
      case "ampurchasing":
        return this.ampThemeID;
      case "cappurchasingalliance":
        return this.capThemeID;
      case "mgmabestprice":
        return this.mgmaThemeID;
      case "tenetgpo":
        return this.tenetThemeID;
      case "aoapurchasepower":
        return this.aoaThemeID;
      case "communitypurchasingprogram":
        return this.commThemeID;
      case "purchasing":
        return this.plusThemeID;
      case "aadmemberbuyingprogram":
        return this.aadThemeID;
      default:
        return this.defaultThemeID;
    }
  }

  isAccountSubDomain(): boolean {
    try {
      let host = location.host;
      let names = host.split(".");
      if (names.length > 2) {
        let subdomain = names[names.length - 3];
        return subdomain === "account";
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  public txt = {
    settingsSaved: "Your account data was saved successfully",
    error: "Error occurred",
    saved: "Saved",
    updated: "Updated",
    done: "Done",
    successfully: "Successfully!",
    removed: "Removed",
    created: "Created",
    wrongCredentials: `We're sorry, your username and password combination did not work.`,
    wrongTotpCode: `Wrong verification code`,
    wrongEmail: `Sorry, this email address appears to be invalid.`,
    passwordSaved: `Password has been changed`,
    wrongPassword: `Wrong password`,
    tmpAuthEmailRequired: "Please input email",
    emailRequired: "Please input email",
    notFound: "Not found",
    tmpAuthTooManyRequests:
      "We've received too many attempts from this email address. Please wait a few minutes and try again",
    emailSendingError: "Sorry, we had an issue sending the email - please try again later",
    serverError: "There was an error on the server. Something bad happened. We're on it.",
    noGpoWarning:
      "This information will be available once we have your enrollment processed. A Client Manager will reach out via email once enrollment has been completed.",
  };

  getCrmFilesTab(locationID: number) {
    return `http://crm.purchase.guru/location/${locationID}?tab=5`;
  }

  getTestimonialsCode(theme_id: number): string {
    switch (theme_id) {
      case this.mgmaThemeID:
        return "F8D3";
      case this.defaultThemeID:
        return "W8BZ";
      default:
        return "";
    }
  }

  getTestimonialsBgColor(theme_id: number): string {
    switch (theme_id) {
      case this.mgmaThemeID:
        return "#64aa24";
      case this.defaultThemeID:
        return "#11B3E9";
      case this.ampThemeID:
        return "#0FA8E2";
      default:
        return "";
    }
  }

  getRentalCodeBusiness(theme_id: number): string {
    switch (theme_id) {
      case this.capThemeID:
        return "XZ01755";
      case this.mgmaThemeID:
        return "XZ01417";
      case this.ampThemeID:
        return "XZWCAMP";
      default:
        return "XZWCPC1";
    }
  }

  getRentalCodeLeisure(theme_id: number): string {
    switch (theme_id) {
      case this.capThemeID:
        return "XZ01757";
      case this.mgmaThemeID:
        return "XZ01416";
      case this.ampThemeID:
        return "XZNCAMP";
      default:
        return "XZNCPC1";
    }
  }

  getAttFanCode(theme_id: number): string {
    switch (theme_id) {
      case this.ampThemeID:
        return "PLASTIC SURGERY PRACTICE SOLUTIONS - 06646564";
      default:
        return "EXPANSION, LLC - 05163300";
    }
  }

  getActualThemes(cb?) {
    this.http.get<ITheme[]>(this.apiUrl + "/theme").subscribe(
      (themes) => {
        if (themes) {
          let map = {};
          themes.forEach((t: ITheme) => {
            map[t.id] = t;
          });
          this.themesMap = map;
          this.setDefaultTheme();
          this.http.get<ISubTheme[]>(this.apiUrl + "/sub_theme").subscribe(
            (subThemes: ISubTheme[]) => {
              if (subThemes && subThemes.length > 0) {
                this.subThemesList = subThemes;
              }
              this.themesAreLoaded = true;
              this.setDefaultTheme();
              if (cb) {
                try {
                  cb();
                } catch (e) {}
              }
            },
            () => {
              this.themesAreLoaded = true;
              this.setDefaultTheme();
              if (cb) {
                try {
                  cb();
                } catch (e) {}
              }
            },
          );
        }
      },
      () => {
        this.themesAreLoaded = true;
        if (cb) {
          try {
            cb();
          } catch (e) {}
        }
      },
    );
  }
}
