import { Component, ViewChild, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import { User } from "../../services/User";
import { Locations } from "../../services/Locations";
import { Distributors } from "../../services/Distributors";
import { Session } from "../../services/Session";
import { ISignupWithPA } from "../../interfaces/ISignupWithPA";
import { IDistributor } from "../../interfaces/IDistributor";
import { Notify } from "../../services/Notify";
import { Config } from "../../services/Config";
import { Helpers } from "../../services/Helpers";
import { UntypedFormBuilder, Validators } from "@angular/forms";

@Component({
  selector: "signup",
  templateUrl: "./signup.html",
})
export class SignupCmp {
  public signup = new ISignupWithPA();
  public currentDate = "";
  public distributorsMap: { [id: number]: IDistributor } = {};
  public distributorsList: IDistributor[] = [];
  public saving = false;
  public isError = false;
  public errMsg = "";
  public notify;

  @ViewChild("elEmail") elEmail: ElementRef;

  key = "6LfbrsEaAAAAAKvnSx_zrBfqVLQQS2BgnlZ9nIqV";

  aFormGroup = this.formBuilder.group({
    captcha: ["", Validators.required],
  });

  constructor(
    private users: User,
    private locations: Locations,
    private distributors: Distributors,
    private router: Router,
    public session: Session,
    private config: Config,
    private h: Helpers,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.h.currentTimeFormattedStringTicker((t) => {
      this.currentDate = t;
    });
    this.distributors.loadToList(this.distributorsList);
    this.notify = new Notify();
  }

  handleReset() {}
  handleExpire() {}
  handleLoad() {}
  handleSuccess($event) {}

  onVerify(token: string) {
    this.signup.captcha_token = token;
  }

  get captcha() {
    return this.aFormGroup.get("captcha");
  }

  submit() {
    if (!this.signup.user.fname) {
      this.notify.error("Sorry, field First Name is required");
      return;
    }
    if (!this.signup.user.lname) {
      this.notify.error("Sorry, field Last Name is required");
      return;
    }
    if (!this.signup.user.email) {
      if (this.elEmail && this.elEmail.nativeElement) {
        try {
          this.signup.user.email = this.elEmail.nativeElement.value;
        } catch (e) {}
      }
    }
    if (!this.signup.user.email) {
      this.notify.error("Sorry, field Email is required");
      return;
    }
    if (this.signup.user.email.indexOf("@") < 1 || this.signup.user.email.length < 6) {
      this.notify.error(
        "Please double-check the email address that you entered. There appears to be a typo.",
      );
      return;
    }
    if (!this.signup.location.facility_name) {
      this.notify.error("Sorry, field Organization’s Legal Name is required");
      return;
    }
    if (!this.signup.location.zip) {
      this.notify.error("Sorry, field  is required");
      return;
    }
    if (!this.signup.location.address) {
      this.notify.error("Sorry, field Organizational Address is required");
      return;
    } else {
      if (this.signup.location.address.length > 70) {
        this.notify.error("Sorry, field Organizational Address is too long (max 70 characters)");
        return;
      }
    }
    if (!this.signup.location.state) {
      this.notify.error("Sorry, field Organizational’s state is required");
      return;
    } else {
      if (this.signup.location.state.length > 40) {
        this.notify.error("Sorry, field Organizational’s state is too long (max 40 characters)");
        return;
      }
    }

    if (!this.signup.signature) {
      this.notify.error("Please accept Terms of Service");
      return;
    }

    if (this.captcha.invalid) {
      this.notify.error("Please use captcha");
      return;
    }

    this.trySignup();
  }

  trySignup() {
    let theme_id = this.config.getThemeIDForHost();
    this.signup.user.theme_id = theme_id;
    this.signup.location.theme_id = theme_id;
    if (theme_id == this.config.defaultThemeID) {
      this.signup.to_generate_rx = true;
    }

    this.saving = true;
    this.users.signupWithPA(this.signup).subscribe(
      (response) => {
        if (
          theme_id == this.config.defaultThemeID &&
          response &&
          response.token &&
          response.rx_code
        ) {
          this.saving = false;
          this.session.signupUrl = window.location.hostname + window.location.pathname;
          this.router.navigateByUrl(
            `/new_member?msg_id=1&code=${response.token}&ask_vac=1&rx=${response.rx_code}`,
          );
        } else {
          this.session.setToken(
            response.token,
            () => {
              this.saving = false;
              this.session.signupUrl = window.location.hostname + window.location.pathname;
              this.router.navigate(["/signup_benefit_review"]);
            },
            () => {
              this.saving = false;
              this.router.navigate(["/login"]);
            },
          );
        }
      },
      (response) => {
        this.isError = true;
        this.saving = false;
        console.error(response);
        if (response && response.status) {
          switch (response.status) {
            case 417:
              this.errMsg =
                "Please double-check the name that you entered. There appears to be a typo.";
              break;
            case 409:
              this.errMsg = "User with this email is already registered";
              break;
            case 406:
              this.errMsg =
                "Please double-check the email address that you entered. There appears to be a typo.";
              break;
            case 400:
              this.errMsg = "Please fill required fields.";
              break;
            case 418:
              this.errMsg =
                "Please double-check the phone number that you entered. There appears to be a typo.";
              break;
            case 419:
              this.errMsg =
                "Please double-check the zip code that you entered. There appears to be a typo.";
              this.signup.user.zip = null;
              break;
            default:
              this.errMsg = "";
          }
          if (this.errMsg) {
            this.notify.error(this.errMsg);
          }
        }
      },
    );
  }
}
